import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

class DialogData {
	message: string;
	accept: () => void;
	cancel: () => void;
	icon: string;
	title: string
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule]
})
export class AlertComponent implements OnInit {

	public title: string = 'Info';
	public icon: string = 'priority_high'

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private dialogRef: MatDialogRef<AlertComponent>
	) { }

	ngOnInit() {
		if (this.data.title != undefined) {
			this.title = this.data.title;
		}
		if (this.data.icon != undefined) {
			this.icon = this.data.icon;
		}
		this.setMessage();
	}

	private setMessage() {
    const messageElement = document.getElementById('div-mensaje')
    if (messageElement) {
      messageElement.innerHTML = this.data.message;
    }
	}

	public cancel() {
		this.data.cancel();
		this.dialogRef.close();
	}

	public accept() {
		this.data.accept();
		this.dialogRef.close();
	}

	public close() {
		this.dialogRef.close();
	}

}
